<template>
  <div class="mt-6">
    <p class="has-text-weight-bold">
      {{ $t("af:profile.statements.title") }}
    </p>

    <p class="has-text-grey-icon">
      {{ $t("af:profile.statements.subtitle") }}
    </p>

    <div
      class="columns setting-tile mx-0 my-2"
      v-for="acceptance in statementAcceptances"
      :key="acceptance.statementId"
    >
      <div class="column">
        <b-checkbox
          class="af-checkbox"
          :name="acceptance.statementIdentifier"
          :disabled="acceptance.isRequired"
          :value="acceptance.isAccepted"
          @input="setAccepted($event, acceptance)"
        />
        <span v-html="processLinks(acceptance.statementLabel)" />
        <span>
          <StatementReaderModal
            v-if="acceptance.documentId"
            :documentId="acceptance.documentId"
            :isRequiredToRead="false"
            :is-public="false"
            :can-cancel="true"
          />
          <StatementLink
            v-if="acceptance.link"
            :link="acceptance.link"
            :isRequiredToRead="false"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UserStatements",
  props: {},
  async mounted() {
    await this.$store.dispatch("profile/fetchStatementAcceptances");
  },
  components: {
    StatementReaderModal: () => import("@/components/StatementReaderModal.vue"),
    StatementLink: () => import("@/components/StatementLink.vue"),
  },
  computed: {
    ...mapGetters({
      statementAcceptances: "profile/getStatementAcceptances",
    }),
  },
  methods: {
    processLinks(text) {
      const regex = /\{\{([^|]+)\|([^}]+)\}\}/g;
      return text.replace(regex, '<a href="$1" target="_blank">$2</a>');
    },
    async setAccepted(checked, acceptance) {
      acceptance.isAccepted = checked;

      try {
        let request = {
          statementId: acceptance.statementId,
          isAccepted: checked,
        };
        await this.$store.dispatch(
          "acceptanceRequirements/acceptStatement",
          request
        );
      } catch (ex) {
        console.error("Statement Acceptance failed!");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.setting-tile {
  background: $grey-2;
  border-radius: 8px;
  padding: 1rem;
}

.af-checkbox {
  vertical-align: middle;
}
</style>
